import React from "react"
import PropTypes from "prop-types"
import { useSpring, animated } from "react-spring"
import Title from "./Title"
import SubTitle from "./SubTitle"
import Action from "./Action"
import Profile from "./Profile"

const HomeContent = ({ bounds }) => {
  const viewWidth = bounds.width

  const { o, xyz } = useSpring({
    from: { o: 0, xyz: [-viewWidth, 0, 0] },
    o: 1,
    xyz: [0, 0, 0],
    delay: 250,
  })
  const { oSub, xyzSub } = useSpring({
    from: { oSub: 0, xyzSub: [-viewWidth, 0, 0] },
    oSub: 1,
    xyzSub: [0, 0, 0],
    delay: 350,
  })
  if (!bounds) return null

  return (
    <>
      <div
        style={{
          zIndex: 2,
        }}
      >
        <Title
          as={animated.div}
          style={{
            // Which works with arrays as well
            transform: xyz.interpolate(
              (x, y, z) => `translate3d(${x}px, ${y}px, ${z}px)`
            ),

            opacity: o.interpolate([0, 1], [0, 1]),
          }}
        >
          Ben Harrison
        </Title>
        <SubTitle
          as={animated.div}
          style={{
            // Which works with arrays as well
            transform: xyzSub.interpolate(
              (x, y, z) => `translate3d(${x}px, ${y}px, ${z}px)`
            ),

            opacity: oSub.interpolate([0, 1], [0, 1]),
          }}
        >
          I am a passionate front-end developer who enjoys building engaging,
          interactive user experiences.
        </SubTitle>
        <Action />
      </div>
      <Profile bounds={bounds} />
    </>
  )
}

HomeContent.propTypes = {
  children: PropTypes.node,
}

HomeContent.defaultProps = {
  children: undefined,
}

export default HomeContent
