import React from "react"
import { navigate } from "gatsby"
import { useSpring } from "react-spring"
import useMeasure from "react-use-measure"
import HomePostListItem from "../HomePostListItem"
import Card from "./Card"
import CardTitle from "./CardTitle"
import CardDate from "./CardDate"

const trans = (x, y, s) =>
  `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`
const tilt = 50
const scale = 1.05
const PostCard = ({ post }) => {
  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }))
  const [homePostListItem, bounds] = useMeasure()
  console.log(bounds.width, bounds.height)
  const calc = (x, y) => {
    return [
      -(y - bounds.y - bounds.height / 2) / 20,
      (x - bounds.x - bounds.width / 2) / 50,
      scale,
    ]
  }

  return (
    <HomePostListItem ref={homePostListItem}>
      <Card
        role="link"
        onClick={() => navigate(post.frontmatter.path)}
        onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
        onMouseLeave={() => set({ xys: [0, 0, 1] })}
        style={{ transform: props.xys.interpolate(trans) }}
      >
        <CardTitle>{post.frontmatter.title}</CardTitle>
        <p>{post.excerpt}</p>
      </Card>
    </HomePostListItem>
  )
}
export default PostCard
