import React from "react"
import PropTypes from "prop-types"
import useMeasure from "react-use-measure"
import HomeContainer from "./HomeContainer"
import HomeContent from "./HomeContent"

const Home = props => {
  const { children } = props
  const [ref, bounds] = useMeasure()

  return (
    <>
      <HomeContainer ref={ref}>
        {bounds?.height > 0 && <HomeContent bounds={bounds} />}
      </HomeContainer>
      {/* <div>{children}</div> */}
    </>
  )
}

Home.propTypes = {
  children: PropTypes.node,
}

Home.defaultProps = {
  children: undefined,
}

export default Home
