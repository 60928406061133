import styled from "styled-components"
import { breakpoints } from "./mediaConstants"

const HomeContainer = styled.div`
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  background-color: black;
  display: flex;
  padding-left: 5vw;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1640px;

  @media only screen and (max-width: ${breakpoints.md}px) {
    padding-left: 0;
    flex-direction: column;
  }

  @media only screen and (max-device-width: ${breakpoints.mobile}px) and (orientation: landscape) {
    flex-direction: row;
    padding-left: 10vw;
  }
`

export default HomeContainer
