import styled from "styled-components"
import { animated } from "react-spring"

const Card = styled(animated.span)`
  display: block;
  cursor: pointer;
  border-radius: 5px;
  background: rgba(250, 255, 255, 0.9);
  padding: 25px;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.8);
  transition: box-shadow 0.5s;
  will-change: transform;

  &:hover {
    box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 0.8);
  }
`

export default Card
