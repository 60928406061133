import styled from "styled-components"
import { breakpoints } from "./mediaConstants"

const SubTitle = styled.h2`
  color: #e2e2e2;
  font-size: 30px;
  line-height: 150%;
  font-weight: 300;
  margin: 0;
  max-width: 545px;
  padding-left: 0.2em;

  @media only screen and (max-width: ${breakpoints.lg}px) {
    font-size: 24px;
    max-width: 445px;
  }
  @media only screen and (max-width: ${breakpoints.md}px) {
    text-align: center;
  }
  @media only screen and (max-device-width: ${breakpoints.mobile}px) {
    font-size: 18px;
    padding: 0 1.5em;
  }
  @media only screen and (max-device-width: ${breakpoints.mobile}px) and (orientation: landscape) {
    text-align: left;
    padding-left: 0;
    max-width: 345px;
  }
`

export default SubTitle
