import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const ActionArea = styled.div`
  min-height: 10vh;
`

const Action = ({ children }) => {
  return <ActionArea>{children}</ActionArea>
}

Action.propTypes = {
  children: PropTypes.node,
}

Action.defaultProps = {
  children: undefined,
}

export default Action
