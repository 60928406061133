import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import PostCard from "../components/posts/PostCard/index"
import Home from "../components/layout/Home"
import HomePostList from "../components/posts/HomePostList"

const IndexPage = ({ data }) => {
  const { edges } = data.allMdx
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostCard key={edge.node.id} post={edge.node} />)

  return (
    <Home>
      <SEO title="Home" />
      <HomePostList>{Posts}</HomePostList>
    </Home>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            date
          }
          excerpt(pruneLength: 250)
        }
      }
    }
  }
`
