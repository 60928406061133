import styled from "styled-components"
import React from "react"
import { useSpring, animated } from "react-spring"
import { breakpoints } from "./mediaConstants"
import Background from "../../../images/profile.jpg"

const IMAGE_ASPECT_RATIO = 1000 / 800

const ProfileImage = styled(animated.div)`
  position: absolute;
  bottom: 0;
  right: 5vw;
  background-image: url(${Background});
  background-size: cover;
  width: ${({ $imageHeight }) => $imageHeight}px;
  height: ${({ $imageHeight }) => $imageHeight * IMAGE_ASPECT_RATIO}px;

  @media only screen and (max-width: ${breakpoints.lg}px) {
    width: ${({ $imageHeight }) => $imageHeight * 0.95}px;
    height: ${({ $imageHeight }) => $imageHeight * 0.95 * IMAGE_ASPECT_RATIO}px;
    right: 2.5vw;
  }
  @media only screen and (max-width: ${breakpoints.md}px) {
    right: 50%;
    margin-right: -${({ $imageHeight }) => ($imageHeight * 0.8) / 2}px;
    width: ${({ $imageHeight }) => $imageHeight * 0.8}px;
    height: ${({ $imageHeight }) => $imageHeight * 0.8 * IMAGE_ASPECT_RATIO}px;
  }

  @media only screen and (max-device-width: ${breakpoints.mobile}px) {
    margin-right: -${({ $imageHeight }) => ($imageHeight * 0.75) / 2}px;
    width: ${({ $imageHeight }) => $imageHeight * 0.75}px;
    height: ${({ $imageHeight }) => $imageHeight * 0.75 * IMAGE_ASPECT_RATIO}px;
  }
  @media only screen and (max-device-width: ${breakpoints.mobile}px) and (orientation: landscape) {
    right: 5vw;
    margin-right: 0;
    width: ${({ $imageHeight }) => $imageHeight}px;
    height: ${({ $imageHeight }) => $imageHeight * IMAGE_ASPECT_RATIO}px;
  }
`

const Gradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0), #000000);
  height: 20vh;
`

const Profile = ({ bounds }) => {
  const imageHeight = bounds.height * 0.72

  const { o, xyz } = useSpring({
    from: { o: 0, xyz: [0, imageHeight, 0] },
    o: 1,
    xyz: [0, 0, 0],
  })

  return (
    <>
      <ProfileImage
        style={{
          // Which works with arrays as well
          transform: xyz.interpolate(
            (x, y, z) => `translate3d(${x}px, ${y}px, ${z}px)`
          ),
          // If you want to combine multiple values use the "interpolate" helper

          opacity: o.interpolate([0, 0.6, 1], [0, 0, 1]),
        }}
        $imageHeight={imageHeight}
      />
      <Gradient />
    </>
  )
}

export default Profile
