import styled from "styled-components"
import { breakpoints } from "./mediaConstants"

const Title = styled.h1`
  display: inline-block;
  color: #fff;
  background: linear-gradient(to right, #fff 0%, #e6e6e6 100%);
  background-size: cover;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;

  margin: 0;
  font-size: 96px;
  line-height: 150%;

  @media only screen and (max-width: ${breakpoints.lg}px) {
    font-size: 70px;
  }
  @media only screen and (max-width: ${breakpoints.md}px) {
    padding-top: 5vh;
    text-align: center;
    width: 100%;
  }
  @media only screen and (max-device-width: ${breakpoints.mobile}px) {
    padding-top: 10vh;
    font-size: 40px;
  }

  @media only screen and (max-device-width: ${breakpoints.mobile}px) and (orientation: landscape) {
    text-align: left;
  }
`

export default Title
