import styled from "styled-components"

const PostList = styled.ul`
  display: flex;
  list-style: none;
  padding: 1ch;
  margin: 0;
`

export default PostList
